import $t from '../../../../util/Translator'

export class LandingFormats {
  static BLOCKS_ID = 0
  static HTML_ID = 1

  static getFormatName = (id) => {
    const format = LandingFormats.getFormats().find(element => element.id === id)
    return format ? format.name : ''
  }

  static getFormatColor = (id) => {
    const format = LandingFormats.getFormats().find(element => element.id === id)
    return format ? format.color : ''
  }

  static getFormat = (id) => {
    return LandingFormats.getFormats().find(element => element.id === id)
  }

  static getFormats = () => {
    const bodyStyles = getComputedStyle(document.body)
    const blocksColorHex = bodyStyles.getPropertyValue('--emphasis-color').trim()
    const htmlColorHex = bodyStyles.getPropertyValue('--tertiary-color').trim()

    return [
      {
        id: LandingFormats.BLOCKS_ID,
        name: $t('Bloques'),
        color: blocksColorHex,
      },
      {
        id: LandingFormats.HTML_ID,
        name: 'HTML',
        color: htmlColorHex,
      },
    ]
  }
}
