var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-btn',{staticClass:"support_button slide-left",staticStyle:{"padding":"0 18px"},attrs:{"elevation":"0","width":"200","ripple":false,"height":"43","rounded":""},on:{"click":_vm.showSendingForm}},[_c('div',{staticClass:"d-flex flex-row",staticStyle:{"display":"block","width":"100%","margin-top":"2px"}},[_c('v-icon',{staticClass:"mr-2",attrs:{"size":"18"}},[_vm._v(" fas fa-headset ")]),_c('div',{staticClass:"mr-5 w-100 text-center"},[_vm._v(" "+_vm._s(_vm.$t('Soporte'))+" ")])],1)]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showForm),expression:"showForm"}]},[_c('validation-observer',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('v-expand-transition',[_c('v-card',{directives:[{name:"click-outside",rawName:"v-click-outside",value:({
            handler: _vm.onClickOutside,
            closeConditional: _vm.onCloseConditional
          }),expression:"{\n            handler: onClickOutside,\n            closeConditional: onCloseConditional\n          }"}],staticClass:"nocard support_form d-flex flex-column",staticStyle:{"border-radius":"0.5rem !important"}},[_c('div',{staticClass:"header_modal gradient-text-color"},[_c('span',[_vm._v(" "+_vm._s(_vm.$t('Deja tu mensaje'))+" "),_c('v-btn',{staticClass:"text-white float-right text-white",attrs:{"color":"transparent","elevation":"0","icon":"","small":""},on:{"click":function($event){_vm.showForm = false}}},[_c('v-icon',{attrs:{"size":"26"}},[_vm._v(" far fa-times-circle ")])],1)],1)]),_c('div',{staticClass:"d-flex flex-column justify-content-center align-items-center h-100 support_form_content"},[_c('v-card-text',{staticClass:"p-4"},[_c('v-row',[_c('v-col',[_c('v-form',{directives:[{name:"show",rawName:"v-show",value:(_vm.showFormInputs),expression:"showFormInputs"}],ref:"formSupport",attrs:{"autocomplete":"off"}},[_c('validation-provider',{attrs:{"name":_vm.$t('Asunto'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":_vm.$t('Asunto'),"error-messages":errors,"filled":"","background-color":"blue-grey lighten-5","required":""},model:{value:(_vm.supportMessage.subject),callback:function ($$v) {_vm.$set(_vm.supportMessage, "subject", $$v)},expression:"supportMessage.subject"}})]}}],null,true)}),_c('validation-provider',{attrs:{"name":_vm.$t('Mensaje'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var errors = ref.errors;
return [_c('v-textarea',{attrs:{"label":_vm.$t('Contenido del mensaje'),"error-messages":errors,"rows":"10","filled":"","background-color":"blue-grey lighten-5","required":""},model:{value:(_vm.supportMessage.message),callback:function ($$v) {_vm.$set(_vm.supportMessage, "message", $$v)},expression:"supportMessage.message"}})]}}],null,true)})],1),(!_vm.showFormInputs)?_c('div',{staticClass:"d-flex justify-content-center word-wrap"},[(_vm.messageResult === 1)?_c('span',[_c('v-icon',{staticClass:"mr-4 mt-n2",attrs:{"size":"26","color":"#2dce89"}},[_vm._v(" fas fa-check ")]),_c('div',{staticClass:"d-inline-block text-break"},[_vm._v(" "+_vm._s(_vm.$t('El mensaje se envió correctamente.'))+" ")])],1):_c('span',[_c('v-icon',{staticClass:"mr-4 mt-n6",attrs:{"size":"26","color":"#f5365c"}},[_vm._v(" fas fa-exclamation-circle ")]),_c('div',{staticClass:"d-inline-block text-break",domProps:{"innerHTML":_vm._s(_vm.$t('El mensaje no pudo ser enviado. <br>Por favor, pruebe de nuevo más tarde.'))}})],1)]):_vm._e()],1)],1)],1),(_vm.showFormInputs)?_c('v-card-actions',{staticClass:"align-self-end"},[_c('v-spacer'),_c('v-btn',{staticClass:"mr-2",attrs:{"text":"","ripple":false,"elevation":"0","filled":"","color":"blue-grey darken-4"},on:{"click":_vm.clearForm}},[_vm._v(" "+_vm._s(_vm.$t('Borrar'))+" ")]),_c('v-btn',{staticClass:"px-5",attrs:{"disabled":invalid,"ripple":false,"elevation":"0","filled":"","color":"theme-secondary-btn text-white"},on:{"click":_vm.sendForm}},[_vm._v(" "+_vm._s(_vm.$t('Enviar'))+" ")])],1):_vm._e()],1)])],1)]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }