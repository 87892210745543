// asset imports
import '@/assets/scss/argon.scss'
import '@/assets/scss/main-theme.scss'
import '@/assets/scss/test-theme.scss'
import '@/assets/scss/nrsgateway-theme.scss'
import '@/assets/scss/wausms-theme.scss'
import '@/assets/scss/altiria-theme.scss'
import '@/assets/scss/sample-green-theme.scss'
import '@/assets/scss/sample-kutxabank-theme.scss'
import '@/assets/scss/sample-bbva-theme.scss'
import '@/assets/scss/sample-madrid-theme.scss'
import '@/assets/scss/sample-renfe-theme.scss'
import '@/assets/scss/sample-ez4u-theme.scss'
import '@/assets/scss/sample-masorange-theme.scss'
import '@/assets/scss/360nrs.scss'
import '@/assets/scss/360nrs-backend.scss'

export default {}
